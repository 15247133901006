<app-PersonalNav [title]="'Dashboard'"></app-PersonalNav> <!-- Componente de navegação pessoal permanece no topo -->
<div class="dashboard-container">
  <div class="main-and-sidebar">
    <div class="main-content">
<div class="container">

  
  
  <!-- Section Profile -->
  <div class="section" id="profile" >
    <img class="iconAlert" *ngIf="!isCompleted" src="assets/iconWarning.png" alt="Warning Icon" >
    <img class="iconAlert" *ngIf="isCompleted" src="assets/iconDone.png" alt="Completed Icon" >
    <img src="assets/iconProfile.png" alt="Profile Icon" class="icon">
    <h2 routerLink="/person" [routerLinkActive]="['active']">PERFIL</h2>
  </div>

  <!-- Section Organization -->
  <div class="section" id="organization" *ngIf="isOrganizationOwner">
    <img class="iconAlert" *ngIf="!isCompleted" src="assets/iconWarning.png" alt="Warning Icon" >
    <img class="iconAlert" *ngIf="isCompleted" src="assets/iconDone.png" alt="Completed Icon" >
    <img src="assets/iconOrganization.png" alt="Organization Icon" class="icon">
    <h2 routerLink="/organization" [routerLinkActive]="['active']">EMPRESA</h2>
  </div>

  <!-- Section Challenge -->
  <div class="section" id="challenge" >
    <img class="iconAlert" *ngIf="!isThereProblem" src="assets/iconWarning.png" alt="Warning Icon" >
    <img class="iconAlert" *ngIf="isThereProblem" src="assets/iconDone.png" alt="Completed Icon" >
    <img src="assets/iconChallenge.png" alt="Challenge Icon" class="icon">
    <h2 routerLink="/pageChallenge" [routerLinkActive]="['active']">DESAFIOS</h2>
  </div>

  <!-- Section Solution -->
  <div class="section" id="solution" >
    <img class="iconAlert" *ngIf="!isThereSolution" src="assets/iconWarning.png" alt="Warning Icon" >
    <img class="iconAlert" *ngIf="isThereSolution" src="assets/iconDone.png" alt="Completed Icon" >
    <img src="assets/iconSolution.png" alt="Solution Icon" class="icon">
    <h2 routerLink="/pageSolution" [routerLinkActive]="['active']">SOLUÇÕES</h2>
  </div>

  <!-- Section Proposta -->
  <div class="section" id="proposta" >
    <img class="iconAlert" *ngIf="!isThereProposal" src="assets/iconWarning.png" alt="Warning Icon" >
    <img class="iconAlert" *ngIf="isThereProposal" src="assets/iconDone.png" alt="Completed Icon" >
    <img src="assets/iconCadastro.png" alt="Proposal Icon" class="icon">
    <h2 routerLink="/proposal" [routerLinkActive]="['active']">PROPOSTA</h2>
</div>

<div class="message mt-4" *ngIf="!isOrganizationOwner">
  <h2></h2>
  <p style="color: cornflowerblue;">
    Para empresa <a (click)="openOrganizationDialog()" style="cursor: pointer;">clique aqui</a>
  </p>
</div>

<!-- WhatsappLinkIcon -->
<div class="whatsapp-contact">
  <a href="https://wa.me/5521998771500" target="_blank" rel="noopener noreferrer">
    <img src="assets/whatsapp.png" alt="WhatsApp Icon">Contate-nos pelo WhatsApp
  </a>
</div>

<div class="sidebar">
  <!-- Componente para o primeiro novo item da sidebar -->
  <div style="display: flex; flex-direction: column; align-items:center;">
    <img class="iconData" src="assets/iconPlan.png" alt="">
    <label style="font-weight: bolder; margin-left:5px">Plano</label>
  </div>
  <!-- Componente para o segundo novo item da sidebar -->

</div>
    </div>
  </div>
</div>