import { NgxSpinnerService } from 'ngx-spinner';
import { FormationAreaService } from './../../../services/formationArea.service';
import { ProblemService } from './../../../services/problem.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Problem } from 'src/Models/problem';
import { UserService } from 'src/api/base/user/user.service';
import { DialogService } from 'src/services/dialog.service';
import { TypeOrganizationService } from 'src/services/typeOrganization.service';
import { TypeOrganization } from 'src/Models/typeOrganization';
import { FormationArea } from 'src/Models/formationArea';
import { ActivatedRoute, Router } from '@angular/router';
import { AccessService } from 'src/services/access.service';
import { OrganizationService } from 'src/services/organization.service';
import { Organization } from 'src/Models/organization';
import { PersonService } from 'src/services/person.service';

@Component({
  selector: 'app-InsertProblem',
  templateUrl: './InsertProblem.component.html',
  styleUrls: ['./InsertProblem.component.css']
})
export class InsertProblemComponent implements OnInit {
  id!: number;
problemForm!: FormGroup;
problem!: Problem;
organization: any;
organizations!: Organization[];
organizationId!: any;
person!: any;
hasOrganizations: boolean = false;
trl = [
  { value: '0', label: 'IDEIA', classificacao: 'Ideia', descricao: 'Nascimento da Ideia (Conceito não comprovado. Nenhum teste foi realizado)' },
  { value: '1', label: 'CONCEITO', classificacao: 'Pesquisa Básica', descricao: 'Início das pesquisa, observação e primeiros relatos (identificação do lastro de conhecimento) {Agora você pode descrever a(s) necessidade(s), mas não tem evidências}' },
  { value: '2', label: 'CONCEITO', classificacao: 'Formulação da Tecnologia', descricao: 'Conceito e aplicação formuladas (concepção de possíveis aplicações) {PRECISA DE VALIDAÇÃO - Você tem uma oferta inicial: as partes interessadas gostam do seu slideware}' },
  { value: '3', label: 'CONCEITO', classificacao: 'PoC - Prova de Conceito', descricao: 'Pronta a POC - Prova de Conceito e aplicação experimental (Testes laboratoriais e Prova de Conceito) {Conceito e aplicação foram formulados}' },
  { value: '4', label: 'PROTÓTIPO', classificacao: 'Teste em Laboratório', descricao: 'Protótipo funcionalidade (Validação da tecnologia em ambiente controlado) {construído em laboratório}' },
  { value: '5', label: 'PROTÓTIPO', classificacao: 'Validação em Ambiente Simulado', descricao: 'Aplicação dentro de uma ambiente simulado e controlado (requerimento mínimo) (Modelo validado em ambiente simulado) {Testado no ambiente pretendido}' },
  { value: '6', label: 'DEMONSTRAÇÃO', classificacao:  'Piloto em Ambiente Relevante', descricao: 'Testado e demonstrado em um ambiente próximo/similar ao real e atingiu a performance esperada (situação próxima do desempenho esperado) {Testado no ambiente pretendido próximo ao desempenho esperado}' },
  { value: '7', label: 'DEMONSTRAÇÃO', classificacao: 'Demonstração em Ambiente Operacional', descricao: 'Testado e demonstrado no ambiente real e atingiu a performance esperada (Protótipo analisado em ambiente operacional) {Operando em ambiente operacional em escala pré-comercial}' },
  { value: '8', label: 'PRODUÇÃO', classificacao: 'Produto em Produção', descricao: 'Todos as funções técnicas do produto estão finalizadas, testadas e o suporte comercial pronto para venda (tecnologia pronta e validada em ambiente real) { Todos os processos e sistemas técnicos de apoio à atividade comercial em estado pronto}' },
  { value: '9', label: 'MERCADO', classificacao: 'Produto à venda no Mercado', descricao: 'Produto pronto, em uso e/ou gerando vendas (O Produto está pronto para ir para o mercado) {Em Produção - Aplicação Comercial Completa}' },
]
user: any;
label: string = 'Cadastrar Desafio';
  typeOrganizations!: {
    classification: string; subClasses: { name: string; types: any; }[]; // Filter out 'Main' if it's the only group
  }[];
  formationAreas!: { classification: any; items: any; }[];
  formattedLimitDateBRL: string = '';
  constructor(private _problem: ProblemService,
    private _user: UserService,
    private _typeOrganization: TypeOrganizationService,
    private _formationArea: FormationAreaService,
    private _access: AccessService,
    private _dialog: DialogService,
    private _activatedRouter: ActivatedRoute,
    private _organization: OrganizationService,
    private _spinner: NgxSpinnerService,
    private _router: Router,
    private _person: PersonService,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.organization = JSON.parse(localStorage.getItem('organizationUser') || '{}');
    this.user = this._user.obterUsuario();
    this.initializeForm();
    this.getTypeOrganization();
    this.getFormationAreas();

  }

  initializeForm() {
   this.problemForm = this.fb.group({
      ownerName: [this.user.nome, Validators.required],
      organization: [this.organization.name, Validators.required],
      area: ['', Validators.required],
      theme: ['', Validators.required],
      challenge: ['', Validators.required],
      mvp: ['', Validators.required], //características específicas do desafio
      operational: ['', Validators.required],
      economic: ['', Validators.required],
      trl: ['', Validators.required],
      availableFees: ['', Validators.required],
      limitDate: ['', Validators.required],
      annex: [''],
      videoLink: ['']
    });
    this.loadData();
    this.organizationId = this.organization.id;
  }

  loadData(){
    this.id = this._activatedRouter.snapshot.params['id'];
    if(this.id) {
      this._problem.getById(this.id)
        .subscribe(result => {
          this.problem = result;
          this.problemForm.patchValue({
            ownerName: this.problem.ownerName,
            organization: this.problem.organizationId,
            area: this.problem.challengeField,
            theme: this.problem.theme,
            challenge: this.problem.description,
            mvp: this.problem.mvpFeat,
            operational: this.problem.operationalGain,
            economic: this.problem.economicGain,
            trl: this.problem.trl,
            availableFees: this.problem.availableFees,
            limitDate: this.problem.limitDate,
            annex: this.problem.urlPresentationData,
            videoLink: this.problem.videoLink
          });
          this.formattedLimitDateBRL = this.problem.limitDate 
              ? this.formatDateToBRL(this.problem.limitDate)
              : '';
          this.label = 'Editar Desafio';
        });
    } else {
      this.label = 'Cadastrar Desafio';
    }
  }

  searchOrganizationId() {
    this.organizationId = JSON.parse(localStorage.getItem('organizationUser') || '{}');
  }

  // Função para converter a data para o formato brasileiro (dd/MM/yyyy)
  formatDateToBRL(dateString: Date): string {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  onSubmit() {
    // Check if the form is valid and show an error with the missing fields if it's not
    if (this.problemForm.invalid) {
      // Get the form controls
      const controls = this.problemForm.controls;
      // Get the invalid controls
      const invalidControls = Object.keys(controls).filter(controlName => controls[controlName].invalid);
      // Get the missing fields
      const missingFields = invalidControls.map(controlName => controls[controlName].errors?.['required'] ? controlName : null).filter(controlName => controlName !== null);
      // Show the error message
      this._dialog.openDialog('Erro', `Por favor, preencha os campos obrigatórios: ${missingFields.join(', ')}`, 'error');
      return;
    }

    let isProfileComplete = this._access.checkIfProfileIsComplete();
    if(!isProfileComplete) {
      this._dialog.openDialog('Erro', 'Para cadastrar um desafio é necessário completar o perfil', 'error');
      return;
    } else {  
      this.saveProblem();
    }
  }

saveProblem() {
  this._person.getById(this.user.id).subscribe({
    next: result => {
      this._spinner.show();
      this.person = result;

      let date = this.problemForm.get('limitDate')?.value;
      let organizationIdValue = this.organizationId.organizationId;

      // Verifique o valor antes da conversão
      console.log('organizationIdValue before conversion:', organizationIdValue);

      // Remove símbolos de moeda para obter o número
      let availableFeesString = this.problemForm.get('availableFees')?.value;
      let availableFees = parseFloat(availableFeesString.replace(/[^\d.-]/g, ''));

      // Cria o objeto problem
      const problem = {
        ownerId: this.user.id,
        ownerName: this.problemForm.get('ownerName')?.value,
        organizationId: Number(organizationIdValue),
        challengeField: this.problemForm.get('area')?.value,
        theme: this.problemForm.get('theme')?.value,
        description: this.problemForm.get('challenge')?.value,
        mvpFeat: this.problemForm.get('mvp')?.value,
        operationalGain: this.problemForm.get('operational')?.value,
        economicGain: this.problemForm.get('economic')?.value,
        tRL: this.problemForm.get('trl')?.value,
        availableFees: availableFees,
        urlPresentationData: this.problemForm.get('annex')?.value,
        videoLink: this.problemForm.get('videoLink')?.value,
        limitDate: date,
        status: 'Ativo',
        userProblemPhone: this.person.phoneNumber,
        userProblemEmail: this.person.email
      };

      if (this.id) {
        // Atualização
        Object.assign(problem, { problemId: this.id });
        this._problem.updateProblem(problem, this.id).subscribe({
          next: () => {
            this._dialog.openDialog('Confirmado', 'Desafio atualizado com sucesso!', 'success');
            this._spinner.hide();
          },
          error: err => {
            console.error('Erro ao atualizar desafio:', err);
            this._spinner.hide();
          }
        });
      } else {
        // Criação
        this._problem.addProblem(problem).subscribe({
          next: () => {
            this._dialog.openDialog('Confirmado', 'Desafio cadastrado com sucesso!', 'success');
            this.problemForm.reset();
            this._spinner.hide();
          },
          error: err => {
            console.error('Erro ao cadastrar desafio:', err);
            this._spinner.hide();
          }
        });
      }
    },
    error: err => {
      console.error('Erro ao buscar pessoa:', err);
      this._spinner.hide();
      this._dialog.openDialog('Erro', 'Não foi possível carregar as informações do usuário.', 'error');
    }
  });
}


  getTypeOrganization() {
    this._typeOrganization.getAll().subscribe(
      (result: TypeOrganization[]) => {
        // Group typeOrganizations by classification
        const classificationGroups: any = result.reduce((acc: any, type: any) => {
          if (!acc[type.classification]) {
            acc[type.classification] = [];
          }
          acc[type.classification].push(type);
          return acc;
        }, {});

        // Further group by subClass within each classification
        this.typeOrganizations = Object.keys(classificationGroups).map(classification => {
          const subClassGroups = classificationGroups[classification].reduce((acc: any, type: any) => {
            const subClass = type.subClass || 'Main';
            if (!acc[subClass]) {
              acc[subClass] = [];
            }
            acc[subClass].push(type);
            return acc;
          }, {});

          return {
            classification: classification,
            subClasses: Object.keys(subClassGroups).map(subClassName => {
              return { name: subClassName, types: subClassGroups[subClassName] };
            }).filter(subGroup => subGroup.name !== 'Main' || subGroup.types.length > 1) // Filter out 'Main' if it's the only group
          };
        });

        // Sort each subClass group by name
        this.typeOrganizations.forEach((group: any) => {
          group.subClasses.forEach((subGroup: any) => {
            subGroup.types.sort((a: any, b: any) => a.name.localeCompare(b.name));
          });
        });
      },
      error => {
        // Handle error
        console.error('Error fetching type organizations', error);
      }
    );
  }

  getFormationAreas() {
    this._formationArea.getAll().subscribe(result => {
      this.formationAreas = this.groupFormationAreas(result);
    });
  }

  groupFormationAreas(areas: any[]) {
    const grouped = new Map();
  
    areas.forEach((area) => {
      const classification = grouped.get(area.classification) || [];
      classification.push(area);
      grouped.set(area.classification, classification);
    });

    //sort the classification and the subjects in the classification
    grouped.forEach((value, key) => {
      value.sort((a: FormationArea, b: FormationArea) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    });

  
    return Array.from(grouped, ([classification, items]) => ({ classification, items }));
  }

  convertToDouble(value: any) {
    if (!value) {
      return '';
    }

    // Returmn the value if it's already a number
    if (typeof value === 'number') {
      return value;
    }
    let unformattedValue = value.replace(/[^0-9,]/g, ''); // Remove everything except digits and comma
    unformattedValue = unformattedValue.replace(',', '.'); // Replace comma with a dot if needed for decimal values

    let numericValue = parseFloat(unformattedValue);
    return numericValue;
  }

}