import { ProposalService } from 'src/services/proposal.service';
import { OrganizationService } from './../../services/organization.service';
import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/api/base/user/user.service';
import { DashboardService } from 'src/services/dashboard.service';
import { PersonService } from 'src/services/person.service';
import { AccessService } from 'src/services/access.service';
import { DialogService } from 'src/services/dialog.service';
import { Router } from '@angular/router';
import { Organization } from 'src/Models/organization';

@Component({
  selector: 'app-Dashboard',
  templateUrl: './Dashboard.component.html',
  styleUrls: ['./Dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  isCompleted: any;
  isThereProblem = false;
  isThereSolution = false;
  isThereProposal = false;
  isOrganizationOwner = false;
  isMaster = false;
  organizationUser: any;
  organizationName: any;
  user: any;

  constructor(private _person: PersonService,
    private _user: UserService,
    private _access: AccessService,
    private _dashboard: DashboardService,
    private _organization: OrganizationService,
    private _proposal: ProposalService,
    private _dialog: DialogService,
    private _router: Router) { }

  ngOnInit() {
    this.user = this._user.obterUsuario();
    this.checkIfPersonIsOwner();
    this._access.checkIfItsLogedIn();
    this.getStatusFromPerson(this.user.id);
    this.checkIfPersonIsCompleted();
    this.checkIfIsThereAnOrganizationInPersonsName();
    this.checkIfThereIsAProposal();
    this.isMaster = this._access.checkIfItsMaster();
    this.searchOrganizationByPersonId(this.user.id);
  }

  getStatusFromPerson(id: number) {
    this._dashboard.getDashboardStatus(id).subscribe(result => {
      this.isThereProblem = result.problemsCompleted;
      this.isThereSolution = result.solutionsCompleted;
    });
  }

  checkIfPersonIsOwner() {
    this.isOrganizationOwner = this._access.checkIfItsOwner();
  }


  checkIfPersonIsCompleted() {
    this._person.getById(this.user.id).subscribe(result => {
      this.isCompleted = result.completedProfile;
    });
  }

  checkIfIsThereAnOrganizationInPersonsName(){
    this._organization.getAll().subscribe(result => {
      let organization = result.find(x => x.responsavelInclusao == this.user.nome);
      if(organization){
        //save in localstorage
        localStorage.setItem('organizationUser', JSON.stringify(organization));
      }
    });
  }

  checkIfThereIsAProposal() {
          this._proposal.getProposalsBySolutionResponsible(this.user.id).subscribe(
        (result: any) => {
          if(result.length > 0){
            this.isThereProposal = true;
          console.log(result);
        }
    });
  }

  searchOrganizationByPersonId(personId: number) {
    this._organization.getOrganizationByPersonId(personId).subscribe(
      (result: Organization[]) => {
        if (result.length > 0) {
          this.organizationName = result[0].name; // Pega o nome da organização
        } else {
          console.warn('Nenhuma organização encontrada');
        }
      },
      (error) => {
        console.log('Erro ao buscar organização:', error);
      }
    );
  }
  

  openOrganizationDialog(): void {
    this._access.checkIfItsLogedIn();
    let isProfileComplete = this._access.checkIfProfileIsComplete();
    if (!isProfileComplete) {
      this._dialog.openDialog(
        'Perfil Incompleto',
        'Complete seu perfil para acessar essa funcionalidade',
        'warning'
      );
      this._router.navigate(['/dashboard']);
    } else {
      this.user = JSON.parse(localStorage.getItem('app.user') || '{}');
      this.organizationUser = JSON.parse(localStorage.getItem('organizationUser') || '{}');
  
      this._dialog
        .confirmDialog(
          'Atenção',
          `Você já está associado(a) à organização <strong>${this.organizationName}</strong>.<br><br>
            Prosseguir com a criação de uma nova empresa substituirá essa associação e 
            você se tornará o <strong>proprietário (owner)</strong> da nova empresa.<br><br>
            ⚠ <em>Isso significa que sua associação atual será perdida e não poderá ser recuperada.</em><br><br>
            Tem certeza de que deseja continuar?`
        )
        .subscribe((result: boolean) => {
          if (result) {
            this._router.navigate(['/organization'])
              .then(success => {
                console.log('Navigation successful:', success);
              })
              .catch(err => {
                console.error('Navigation failed:', err);
              });
          }
        });
    }
  }
  


}
