import { DialogService } from './../../../services/dialog.service';
import { SolutionService } from './../../../services/solution.service';
import { ProposalService } from './../../../services/proposal.service';
import { MatTableDataSource } from '@angular/material/table';
import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/api/base/user/user.service';
import { ProblemService } from 'src/services/problem.service';
import { AccessService } from 'src/services/access.service';

@Component({
  selector: 'app-ReceivedProposals',
  templateUrl: './ReceivedProposals.component.html',
  styleUrls: ['./ReceivedProposals.component.css']
})
export class ReceivedProposalsComponent implements OnInit {

  statusOptions = ['Entregue', 'Em avaliação', 'Aprovada', 'Em contratação', 'Reprovada'];

  get displayedColumns(): string[] {
    // Inclui 'details' somente se selectedChallenge estiver definido
    const columns = [
      'proposalId', 'organization', 'ownerName', 'challengeField', 
      'theme', 'description', 'trl', 'availableFees', 'status'
    ];
  
    if (this.selectedChallenge) {
      columns.push('details');
    }
  
    return columns;
  }
  

  proposals: any;
  solutionsInTable = new MatTableDataSource<any>();
  user: any;
  selectedChallenge: any;
  challenges: any;
  solutions: any;

  constructor(private _proposals: ProposalService,
    private _problem: ProblemService,
    private _dialog: DialogService,
    private _access: AccessService,
    private _user: UserService) {}

  ngOnInit() {
    this._access.checkIfItsLogedIn();
    this.user = this._user.obterUsuario();
    // Implement your logic here
    this.getData();
  }

  getData() {
    this._proposals.getProposalReceived(this.user.id).subscribe(
      (data: any) => {
        this.proposals = new MatTableDataSource(data);
        this.solutions = data.map((p: { solution: any; }) => {
          const solution = p.solution;
  
          // Adicionando o nome do responsável
          solution.UserNameResponsibleSolution =
            solution.personSolutions?.[0]?.person?.name || 'Não informado';
  
          return solution;
        });
        this.challenges = data.map((p: { problem: any; }) => p.problem);
        this.solutionsInTable = new MatTableDataSource(this.solutions);
  
        // Adicionar o nome do responsável pela solução
        this.solutions.forEach((solution: any) => {
          if (solution.PersonSolutions && solution.PersonSolutions.length > 0) {
            solution.UserNameResponsibleSolution = solution.PersonSolutions[0]?.Person?.Name;
          }
        });

      }
    );
  }

  updateStatus(element: any, target: any) {
    // Update the status of the element, might need to update the data source or server
    if (target instanceof HTMLSelectElement) {
      const newStatus = target.value;
      element.status = newStatus;
      // Implement the logic to update this status in your data source or backend
    }
    // Implement the logic to update this status in your data source or backend
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
  }

  getSolutionByChallenge(event: any) {
    const challengeId = event.value.problemId;
  
    if (challengeId) {
      const filteredSolutions = this.proposals.data
        .filter((p: { problem: any; }) => p.problem.problemId === challengeId)
        .map((p: { solution: any; }) => p.solution);

      this.solutionsInTable = new MatTableDataSource(filteredSolutions);
    } else {
      this.solutionsInTable = new MatTableDataSource(this.solutions);
    }
  }
  

  openDialog(solution: any, challenge: any) {
    if (!challenge) {
    this._proposals.getProposalBySolution(solution.solutionId).subscribe(
      (data: any) => {
        debugger;
        challenge = data.problem;
      }
    );
  }
  
    // Passa os dados para o serviço de diálogo
    this._dialog.compareDialog(solution, challenge);
  }
  
}

