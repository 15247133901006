import { DialogDataProposal } from './../../shared/NotificationDialog/NotificationDialog.component';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from 'src/app/shared/NotificationDialog/NotificationDialog.component';
import { Solution } from 'src/Models/solution';
import { ProblemService } from 'src/services/problem.service';
import { ProposalService } from 'src/services/proposal.service';
import { SolutionService } from 'src/services/solution.service';
import { DialogService } from 'src/services/dialog.service';
import { Problem } from 'src/Models/problem';
import { PersonService } from 'src/services/person.service';

@Component({
  selector: 'app-DialogProposal',
  templateUrl: './DialogProposal.component.html',
  styleUrls: ['./DialogProposal.component.css']
})
export class DialogProposalComponent implements OnInit {

  solutionDetails: any; 
  problemDetails: any; 
  userSolutions: any[] = [];
  userProblems: any[] = [];
  selectedSolutionId: any;
  selectedProblemId: any;
  selectedSolutionDetails!: Solution;
  selectedProblemDetails!: Problem;
  specificSolutionDetail: string = '';
  user: any;
  person: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogDataProposal,
    private problemService: ProblemService,
    private solutionService: SolutionService,
    private dialogmessage: DialogService,
    private proposalService: ProposalService,
    private _person: PersonService
  ) {}

  ngOnInit(): void {
    if (this.data.problem) {
      this.loadProblemDetails(this.data.problem.problemId);
    }
  
    if (this.data.solution) {
      this.loadSolutionDetails(this.data.solution.solutionId);
    }
  
    this.loadUserSolutions();
    this.loadUserProblems();

    this._person.getById(this.user.id).subscribe(result => {
      this.person = result;
    });
  }

  onSolutionSelected(solutionId: number): void {
    this.selectedSolutionId = solutionId;
    // Fetch and store the selected solution details
    this.solutionService.getById(solutionId).subscribe(
      (solution: Solution) => {
        this.selectedSolutionDetails = solution;
      },
      error => {
        console.error('Error fetching solution details', error);

      }
    );
  }

  onProblemSelected(problemId: number): void {
    this.selectedProblemId = problemId;
    // Fetch and store the selected solution details
    this.problemService.getById(problemId).subscribe(
      (problem: Problem) => {
        this.selectedProblemDetails = problem;
      },
      error => {
        console.error('Error fetching problem details', error);

      }
    );
  }
  
  loadProblemDetails(problemId: number): void {
    this.problemService.getById(problemId).subscribe(
      details => {
        this.problemDetails = details}
    );
  }

  loadSolutionDetails(solutionId: number): void {
    this.solutionService.getById(solutionId).subscribe(
      details => {
        this.solutionDetails = details}
    );
  }

  loadUserSolutions(): void {
    // Implemente a lógica para carregar as soluções do usuário
    // Exemplo:
    this.user = JSON.parse(localStorage.getItem('app.user')!);
    this.solutionService.getAll().subscribe(
      solutions => {
        this.userSolutions = solutions.filter((solution: Solution) => solution.organizationResponsibleId == this.user.id);
      } 
    );
  }

  loadUserProblems(): void {
    // Implemente a lógica para carregar as soluções do usuário
    // Exemplo:
    this.user = JSON.parse(localStorage.getItem('app.user')!);
    this.problemService.getAll().subscribe(
      solutions => {
        this.userProblems = solutions.filter((problem: Problem) => problem.ownerId == this.user.id);
      } 
    );
  }

  findSolutionType(solutionId: number): string {
    //fetch the solution in the logged user solutions
    const solution = this.userSolutions.find((solution: Solution) => solution.solutionId == solutionId);
    if (solution) {
      return solution.typeOfSolution;
    }

    return '';
  }

  submitProposal(): void {
    const proposal = {
      problemId: this.selectedProblemId || this.data.problem?.problemId,
      solutionId: this.selectedSolutionId || this.data.solution?.solutionId,
      personId: this.user.id,
      UserNameResponsibleProposal: this.person.name
    };
  
    this.proposalService.addProposal(proposal).subscribe(
      response => {
        this.dialogmessage.openDialog('Sucesso', 'Proposta enviada com sucesso', 'success');
      },
      error => {
        console.error('Erro ao enviar a proposta', error);
      }
    );
  }
  
}
