<app-PersonalNav [title]="label"></app-PersonalNav>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-multiple" [fullScreen]="true">
  <p style="color: white"> Carregando... </p>
</ngx-spinner>
<div class="container mt-3">
  <!-- <h1>{{label}}</h1> -->
  <hr>
  <form [formGroup]="problemForm" (ngSubmit)="onSubmit()" class="profile-form">
    
    <!-- Organization as required field -->
    

    <div class="form-group row">
      <div class="left-column col-sm-4 col-form-label">
        <label for="organization">Empresa (Nome do Desafiador) <span class="required-indicator">*</span></label>
      </div>
      <div class="col-sm-8">
        <input type="text" class="form-control" id="organization" formControlName="ownerName">
      </div>
    </div>

    <!-- Choose Organization -->
    <!-- <div class="form-group row" *ngIf="hasOrganizations; else noOrganizations">
      <div class="left-column col-sm-4 col-form-label">
        <label for="organization"> Organização <span class="required-indicator">*</span></label>
      </div>
      <div class="col-sm-8">
        <select name="organization" formControlName="organization" class="form-select">
          <option value="" disabled selected>Selecione uma opção</option>
          <option *ngFor="let organization of organizations" [value]="organization.organizationId">{{organization.name}}</option>
        </select>
      </div>
    </div>
    <ng-template #noOrganizations>
      <div class="form-group row">
        <div class="left-column col-sm-4 col-form-label">
          <label for="organization">Organização *</label>
        </div>
        <div class="col-sm-8">
          <p>Não há organização ativa em seu perfil. Para cadastrar um desafio, espere para que aprovemos sua organização recém cadastrada, ou ative alguma de suas organizações.</p>
        </div>
      </div>
    </ng-template> -->

   <!-- Organization -->
   <div class="form-group row">
    <div class="left-column col-sm-4 col-form-label">
      <label for="organization">Nome da Empresa <span class="required-indicator">*</span></label>
    </div>
    <div class="col-sm-8">
      <input type="text" class="form-control" id="organization" formControlName="organization" readonly>
    </div>
  </div>
    <!-- <ng-template #noOrganizations>
      <div class="form-group row">
        <div class="left-column col-sm-4 col-form-label">
          <label for="organization">Organização *</label>
        </div>
        <div class="col-sm-8">
          <p>Não há organização ativa em seu perfil. Para cadastrar um desafio, espere para que aprovemos sua organização recém cadastrada, ou ative alguma de suas organizações.</p>
        </div>
      </div>
    </ng-template> -->

    <!-- Area -->
    <div class="form-group row">
      <div class="left-column col-sm-4 col-form-label">
        <label for="area">Área do Desafio <span class="required-indicator">*</span></label>
      </div>
      <div class="col-sm-8">
        <select name="area" formControlName="area" class="form-select">
          <option value="" disabled selected>Selecione uma opção</option>
          <ng-container *ngFor="let group of formationAreas">
            <optgroup label="{{ group.classification }}">
              <option *ngFor="let item of group.items" [value]="item.name">{{item.name}}</option>
            </optgroup>
          </ng-container>
        </select>
      </div>
    </div>

    <!-- Theme -->
    <div class="form-group row">
      <div class="left-column col-sm-4 col-form-label">
        <label for="theme">Nome do Desafio <span class="required-indicator">*</span></label>
      </div>
      <div class="col-sm-8">
        <input type="text" class="form-control" id="theme" formControlName="theme">
      </div>
    </div>

    <!-- Challenge Description -->
    <div class="form-group row">
      <div class="left-column col-sm-4 col-form-label">
        <label for="challenge">Descrição do Desafio <span class="required-indicator">*</span></label>
      </div>
      <div class="col-sm-8">
        <input class="form-control" id="challenge"  formControlName="challenge">
      </div>
    </div>

    <!-- Objectives -->
    <!-- ... previous form groups ... -->



<!-- MVP Features -->
<div class="form-group row">
  <div class="left-column col-sm-4 col-form-label">
    <label for="mvpFeatures">Categoria Específica do Desafio <span class="required-indicator">*</span></label>
  </div>
  <div class="col-sm-8">
    <input class="form-control" id="mvpFeatures"  formControlName="mvp">
  </div>
</div>

<!-- Operational Gains -->
<div class="form-group row">
  <div class="left-column col-sm-4 col-form-label">
    <label for="operationalGains">Ganhos Operacionais</label>
  </div>
  <div class="col-sm-8">
    <input class="form-control" id="operationalGains"  formControlName="operational">
  </div>
</div>

<!-- Economic Gains -->
<div class="form-group row">
  <div class="left-column col-sm-4 col-form-label">
    <label for="economicGains">Ganhos Econômicos</label>
  </div>
  <div class="col-sm-8">
    <input class="form-control" id="economicGains" rows="3" formControlName="economic">
  </div>
</div>

<!-- Desired Maturity -->
<div class="form-group row">
  <div class="left-column col-sm-4 col-form-label">
    <label for="desiredMaturity">Maturidade Desejada (TRL) <span class="required-indicator">*</span></label>
  </div>
  <div class="col-sm-8">
    <select class="form-select" name="desiredMaturity" formControlName="trl" id="trl">
        <option value="" disabled selected>Selecione uma opção</option>
        <option *ngFor="let item of trl" [value]="item.value" matTooltip="{{item.descricao}}">
            {{item.value}} - {{item.label}} - {{item.classificacao}}
    </select>
  </div>
</div>


<!-- Available Funds -->
<div class="form-group row">
  <div class="left-column col-sm-4 col-form-label">
    <label for="availableFunds">Verba Disponível <span class="required-indicator">*</span></label>
  </div>
  <div class="col-sm-8">
    <input type="text" class="form-control" id="availableFunds" formControlName="availableFees" mask="separator.3" prefix="R$" thousandSeparator="." decimalMarker="," [dropSpecialCharacters]="false">
  </div>
</div>

<!-- Annex -->
<div class="form-group row">
  <div class="left-column col-sm-4 col-form-label">
    <label for="annex">Anexos do Projeto</label>
  </div>
  <div class="col-sm-8">
    <app-file-upload formControlName="annex" [allowedTypes]="['application/pdf']"></app-file-upload>
  </div>
</div>

<!-- Video Link -->
<div class="form-group row">
  <div class="left-column col-sm-4 col-form-label">
    <label for="videoLink">Link do Vídeo de Apresentação</label>
  </div>
  <div class="col-sm-8">
    <input type="url" class="form-control" id="videoLink" formControlName="videoLink">
  </div>
</div>

<!-- Deadline -->
<div class="form-group row">
  <div class="left-column col-sm-4 col-form-label">
    <label for="deadline">Prazo Limite Envio de Proposta <span class="required-indicator">*</span></label>
  </div>
  <div class="col-sm-8">
    <input type="date" class="form-control" id="deadline" formControlName="limitDate">
  </div>
</div>



<!-- Submit Button -->
<div class="form-group row">
  <div class="col-sm-12 d-flex justify-content-end">
    <button type="submit"  mat-raised-button color="warn">Salvar</button>
  </div>
</div>


  </form>
</div>
