import { PersonOrganizationDTO } from './../Models/PersonOrganizationDTO';
import { EditEmployeeDialogComponent } from './../app/Administration/OwnerAdmin/EditEmployeeDialog/EditEmployeeDialog.component';
import { AddOrganizationAdminComponent } from './../app/Organization/AddOrganizationAdmin/AddOrganizationAdmin.component';
import { AssignAdminToOrganizationComponent } from './../app/shared/AssignAdminToOrganization/AssignAdminToOrganization.component';
import { DialogCompareComponent } from './../app/shared/DialogCompare/DialogCompare.component';

import { InfoTableDialogComponent } from './../app/shared/InfoTableDialog/InfoTableDialog.component';
import { DialogProposalComponent } from './../app/Proposal/DialogProposal/DialogProposal.component';
import { Solution } from 'src/Models/solution';
import { EventEmitter, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotificationDialogComponent } from 'src/app/shared/NotificationDialog/NotificationDialog.component';
import { Problem } from 'src/Models/problem';
import { DialogMyProposalComponent } from 'src/app/Proposal/MyProposals/dialogMyProposal/dialogMyProposal.component';
import { ConfirmDialogComponent } from 'src/app/shared/ConfirmDialog/ConfirmDialog.component';
import { AssignDialogRoleComponent } from 'src/app/shared/AssignDialogRole/AssignDialogRole.component';
import { ResetPasswordComponent } from 'src/api/base/user/resetPassword/resetPassword.component';
import { AddRoleDialogComponent } from 'src/api/base/user/AddRoleDialog/AddRoleDialog.component';


@Injectable({
  providedIn: 'root'
})
export class DialogService {

  onUpdatedFinished: EventEmitter<void> = new EventEmitter<void>();

  constructor(public dialog: MatDialog) { }

openDialog(title: string, message: string, type: 'success' | 'error' | 'warning'): void {
  this.dialog.open(NotificationDialogComponent, {
    width: '350px',
    data: { title, message, type }
  });
}

openDialogForTableInfo(dataProblem?: Problem, dataSolution?: Solution){
  this.dialog.open(InfoTableDialogComponent, {
    width: 'auto',

    data: { dataProblem, dataSolution },
    disableClose: true,
  })
}

openProposal(problem?: Problem, solution?: Solution){
  this.dialog.open(DialogProposalComponent, {
    width: '100%',

    data: { problem, solution },
    disableClose: true,
  })
}

openMyProposals(proposal: any){
  this.dialog.open(DialogMyProposalComponent, {
    width: '100%',
    data: { proposal },
    disableClose: true,
  })
}

confirmDialog(title: string, message: string): any {
  return this.dialog.open(ConfirmDialogComponent, {
    width: '350px',
    data: { title, message }
  }).afterClosed(); 
}


compareDialog(solution: any, challenge: any){
  this.dialog.open(DialogCompareComponent, {
    width: '100%',
    height: '70vh',
    data: { solution, challenge },
    disableClose: true,
  })
}

openAssignRoleDialog(userId: string, roles: any): void {
  const dialogRef = this.dialog.open(AssignDialogRoleComponent, {
    width: '250px',
    data: { userId, roles }
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed');
    this.onUpdatedFinished.emit();
  });
}

resetPassword(){
  const dialogRef = this.dialog.open(ResetPasswordComponent, {
    width: '400px',
    disableClose: true,
  });

  return dialogRef;

}


openAddRoleDialog(): void {
  const dialogRef = this.dialog.open(AddRoleDialogComponent, {
    width: '350px',
  });

  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      // Handle the role creation logic here, or emit an event
      console.log('Role name:', result);
      this.onUpdatedFinished.emit();
    }
  });
}


openAddAdminToOrganization(): void {
  const dialogRef = this.dialog.open(AssignAdminToOrganizationComponent, {
    width: '350px',
    });

  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      // Handle the role creation logic here, or emit an event
      console.log('Role name:', result);
      this.onUpdatedFinished.emit();
    }
  }
  );
}

openAddOrganizationAdmin(organizationId: number, solutionId?: number): void {
  const dialogRef = this.dialog.open(AddOrganizationAdminComponent, {
    width: '350px',
    data: { organizationId, solutionId }
    });

  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      // Handle the role creation logic here, or emit an event
      console.log('Role name:', result);
      this.onUpdatedFinished.emit();
    }
  }
  );
}

editEmployee(personOrganization: PersonOrganizationDTO, user: any) {
  this.dialog.open(EditEmployeeDialogComponent, {
    width: '400px',
    data: { personOrganization: personOrganization, user: user }
  });
}




}
